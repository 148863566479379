
















import Vue from 'vue'
import { BAlert } from 'bootstrap-vue'

export default Vue.extend({
  components: {
    BAlert,
  },
})
